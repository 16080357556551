<template>
    <div class="project-template-page">
        <div class="page-tools-content" style="margin: 0;">
            <div class="left">
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage()">{{$t('project.project_template_page_btn_1')}}</el-button>
                <el-button size="small" plain round icon="el-icon-delete" v-permission="``" @click="deleteBtnEvent">{{$t('project.project_template_page_btn_2')}}</el-button>
            </div>
            <div class="right">
                <div style="width:200px;">
                    <el-input :placeholder="$t('project.project_template_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
            </div>
        </div>
        <el-table :data="list" @selection-change="handleSelectionChange" style="width: 100%;">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="templateName" :label="$t('project.project_template_page_table_column_1')" min-width="120"></el-table-column>
          <el-table-column :label="$t('project.project_template_page_table_column_2')" min-width="120">
            <template slot-scope="scope">
                <el-tooltip placement="top-start">
                    <template slot="content">
                        <div class="line-height-1.5" style="max-width: 400px;">
                            <span class="language-block">{{ scope.row.sourceLang.shortName }}</span>
                            <svg-icon name="ib-arrow-right" style="margin:0 4px;"></svg-icon>
                            <span class="language-block">{{ scope.row.targetLang.map(item => item.shortName).join('/') }}</span>
                        </div>
                    </template>
                    <span class="text-omission">
                        <span class="language-block">{{ scope.row.sourceLang.shortName }}</span>
                        <svg-icon name="ib-arrow-right" style="font-size:12px;margin:0 4px;"></svg-icon>
                        <span class="language-block">{{ scope.row.targetLang.map(item => item.shortName).join('/') }}</span>
                    </span>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="translationProcessModeStr" :label="$t('project.project_template_page_table_column_3')" min-width="120"></el-table-column>
          <el-table-column prop="teamName" :label="$t('project.project_template_page_table_column_4')" min-width="120"></el-table-column>
          <el-table-column prop="creatorName" :label="$t('project.project_template_page_table_column_5')" min-width="120"></el-table-column>
          <el-table-column prop="createTime" :label="$t('project.project_template_page_table_column_6')" min-width="120"></el-table-column>
          <el-table-column :label="$t('project.project_template_page_table_column_7')" width="80">
            <template slot-scope="scope">
                <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command, scope.row)">
                        <div class="fonts-20 cursor-pointer">
                            <svg-icon name="ib-more"></svg-icon>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="editName" v-permission="``">{{$t('project.project_template_page_dropdown_item_1')}}</el-dropdown-item>
                            <el-dropdown-item command="delete" divided v-permission="``" style="color:#F56C6C;">{{$t('project.project_template_page_dropdown_item_2')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('project.project_template_page_dialog_1_title')" center :visible.sync="nameDialog.show" destroy-on-close width="480px" top="20px" @close="nameDialog.form.name = ''">
            <el-form class="padding-x-20">
                <el-form-item  required>
                    <el-input :placeholder="$t('project.project_template_page_dialog_1_input_placeholder_1')" v-model="nameDialog.form.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="nameDialog.show = false" round plain size="small">{{$t('project.project_template_page_dialog_1_btn_1')}}</el-button>
                <el-button :loading="nameDialog.loading" @click="saveRenameBtnEvent" round type="primary" size="small" style="margin-left:30px;" >{{$t('project.project_template_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent">
        </alert-dialog>
    </div>
</template>
<script>
import { projectApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog.vue';
export default {
    components: {
        AlertDialog
    },
    data() {
        return {
            alertInfo:{
                message: this.$t('project.project_template_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('project.project_template_page_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            searchForm: {
                kw: '',
            },
            list: [],
            total: 0,
            refreshLoading: false,
            pageNumber: 1,
            pageSize: 16,
            searchTimer: null,
            selectedItem: [],
            nameDialog: {
                show: false,
                loading: false,
                param: null,
                form: {
                    name: '',
                }
            }

        }
    },
    methods: {
        initPage() {
            let url = `${projectApi.queryTemplateListPage}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}`;
            if (this.searchForm.kw) {
                url += `&keyword=${encodeURI(this.searchForm.kw)}`;
            }
            this.refreshLoading = true;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200) {
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(()=>{
                this.refreshLoading = false;
            })
        },
        inputSearchEvent (val) {
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                this.initPage();
            },500);
        },
        handleSelectionChange(val) {
            this.selectedItem = val;
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.initPage();
        },
        editTemplateName (row) {
            this.nameDialog.param = row;
            this.nameDialog.form.name = row.templateName;
            this.nameDialog.show = true;
        },
        saveRenameBtnEvent () {
            if (!this.nameDialog.form.name) {
                this.$message.error(this.$t('project.project_template_page_method_saveRenameBtnEvent_message_1'));
                return;
            }
            let url = projectApi.editTemplate;
            this.nameDialog.loading = true;
            this.$ajax.put(url, {id: this.nameDialog.param.templateId, name: this.nameDialog.form.name}).then(res => {
                if (res.status === 200) {
                    this.$message.success(this.$t('project.project_template_page_method_saveRenameBtnEvent_message_2'));
                    this.nameDialog.show = false;
                    this.initPage();
                }
            }).finally(() => {
                this.nameDialog.loading = false;
            })
        },
        deleteBtnEvent () {
            console.log(this.selectedItem);
            if(this.selectedItem.length === 0){
                this.$message.error(this.$t('project.project_template_page_method_deleteBtnEvent_message_1'));
                return;
            }
            this.alertInfo.message = this.$t('project.project_template_page_method_deleteBtnEvent_message_2');
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteTemplateItem (row) {
            this.selectedItem = [row];
            this.alertInfo.message = this.$t('project.project_template_page_method_deleteTemplateItem_message_1');
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteConfirmEvent() {
            let postData = {
                ids: this.selectedItem.map(item => item.templateId)
            };
            let url = projectApi.deleteTemplate;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    this.$message.success(this.$t('project.project_template_page_method_deleteConfirmEvent_message_1'));
                    this.initPage();
                    this.selectedItem = [];
                }
            }).finally(() => {

            })
        },
        handleMoreCommand (command, row) {
            switch (command) {
                case 'editName':
                    this.editTemplateName(row);
                    break;
                case 'delete':
                    this.deleteTemplateItem(row);
                    break;
            }
        },
        
    },
    mounted() {
        this.initPage();
    }
}
</script>
<style lang="scss" scoped>
.project-template-page{
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
}
</style>
